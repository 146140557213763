<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="px-2 mx-4 my-1 py-1 " v-if="popupDialog == false">
      <v-card-title
        ><v-icon class="pr-4" large>mdi-account-cash-outline</v-icon> Ingresar
        pago de cliente</v-card-title
      >
    </v-card>
    <v-card class="px-4 mx-4 my-4 py-4" v-if="datosPago.party_id_from == ''">
      <v-row>
        <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
          <v-select
            v-model="datosPago.payment_type_id"
            small
            item-text="tp_nombre"
            item-value="payment_type_id"
            :items="paymentTypes"
            label="Tipo de pago"
          ></v-select>
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
          <v-text-field
            label="Buscar cliente"
            @click="abrirDialogoBuscarCliente"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-4 mx-4 my-2 py-2" v-if="datosPago.party_id_from != ''">
      <v-card-title>Datos del pago</v-card-title>
      <v-card-text>
        <v-form ref="formPago">
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" sm="8" md="8">
              <v-text-field
                v-model="datosPago.cliente_nombres"
                small
                readonly
                label="Cliente"
              ></v-text-field>

              <v-select
                class="pt-0 pb-0"
                v-model="datosPago.payment_method_type_id"
                small
                item-text="description"
                item-value="payment_method_type_id"
                :items="formasPagoPermiso"
                label="Forma de pago"
                :rules="finAccountRule"
                @change="cambioPmt()"
              ></v-select>

              <v-select
                class="pt-0 pb-0"
                v-if="datosPago.payment_method_type_id == 'EFT_ACCOUNT'"
                v-model="datosPago.fin_account_id"
                small
                item-text="fin_account_name"
                item-value="fin_account_id"
                :items="cuentasBancarias"
                label="Cuenta bancaria"
                :rules="[validarCuentaBancaria]"
              ></v-select>

              <v-select
                sm="8"
                md="8"
                class="pt-0 pb-0"
                v-if="
                  datosPago.payment_method_type_id == 'CREDIT_CARD' ||
                    datosPago.payment_method_type_id == 'CREDIT_CARD_DIF' ||
                    datosPago.payment_method_type_id == 'CREDIT_CARD_DIF_INT' ||
                    datosPago.payment_method_type_id == 'DEBIT_CARD'
                "
                v-model="datosPago.payment_method_id"
                small
                item-text="description"
                item-value="payment_method_id"
                :items="getTiposTarjetaFp"
                label="Tipo de tarjeta"
                :rules="[validarTipoTarjeta]"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
              <v-text-field
                v-model="datosPago.effective_date"
                label="Fecha"
                type="date"
                small
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
              <v-text-field
                class="pt-0 pb-0"
                v-model="datosPago.payment_ref_num"
                label="Referencia"
                type="text"
                small
                :rules="[validarRefPago]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
              <v-text-field
                class="pt-0 pb-0"
                v-model="datosPago.monto"
                label="Monto pagado"
                type="text"
                small
                :rules="[validarMontoPago]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" :loading="btnGuardarPagoLoad" :disabled="btnGuardarPagoLoad" class="white--text" @click="guardarPago"
          ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogBuscarCliente" persistent max-width="700px">
      <v-card>
        <v-form
          ref="form_search_client"
          @submit="submit"
          onsubmit="return false"
          class="mt-2"
        >
          <v-row class="mx-1">
            <v-col cols="12" class="text-center py-0 py-md-3 col-md-12 ">
              <v-text-field
                :rules="ruleRequired"
                append-icon="mdi-magnify"
                @click:append="buscarCliente"
                @keyup.enter="buscarCliente"
                v-model="cliente"
                label="Buscar cliente por nombre, identificación, o ID"
                :loading="searchClientLoading"
                class="mx-5"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-col cols="12" class="text-center pt-0" v-if="!newClient">
          <v-data-table
            v-model="clientSelected"
            :headers="headersClient"
            :items="dataTableClient"
            :items-per-page="15"
            :single-select="true"
            :loading="loadingTableClient"
            item-key="party_id"
            loading-text="Buscando cliente..."
            class="elevation-1"
            show-select
            dense
          >
            <template v-slot:footer>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  class="mx-1"
                  @click="seleccionarCliente"
                  small
                  v-if="clienteSeleccionado"
                >
                  <v-icon>mdi-skip-next</v-icon> Seleccionar
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="closeDialogClient"
                  class="mx-1"
                  small
                >
                  <v-icon>mdi-cancel</v-icon> Cancelar
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AccountingEntries from "../general/AccountingEntries";
import ExpandableFilters from "../general/ExpandableFilters";
import Vue from "vue";

export default {
  name: "FormNuevoPagoRecibido",
  components: {
    ExpandableFilters,
    AccountingEntries,
  },
  props: {
    popupDialog: Boolean,
  },
  data: () => ({
    options: {},

    notifications: false,
    overlay: false,

    datosPago: {
      payment_type_id: "CUSTOMER_PAYMENT",
      payment_ref_num: "",
      party_id_from: "",
      party_id_to: "",
      cliente_nombres: "",
      effective_date: new Date().toISOString().slice(0, 10),
      monto: 0,
      fin_account_id: "",
      payment_method_id: "",
      payment_method_type_id: "",
      pct_retencion: 0,
      monto_base: 0,
    },

    paymentTypes: [
      { payment_type_id: "CUSTOMER_PAYMENT", tp_nombre: "Pago de cliente" },
    ],
    tiposTarjeta: [],
    dialogPago: false,
    paymentMethodTypes: [
      { payment_method_type_id: "CASH", description: "Efectivo" },
      { payment_method_type_id: "EFT_ACCOUNT", description: "Transferencia" },
      { payment_method_type_id: "PERSONAL_CHECK", description: "Cheque" },
      {
        payment_method_type_id: "CREDIT_CARD",
        description: "Tarjeta de crédito",
      },
      {
        payment_method_type_id: "DEBIT_CARD",
        description: "Tarjeta de débito",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF",
        description: "Tarjeta de crédito diferido",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF_INT",
        description: "Tarjeta de crédito diferido con intereses",
      },
      {
        payment_method_type_id: "BAJA_CARTERA_CC",
        description: "Baja de cartera cliente",
      },
    ],
    pmtRet: [
      {
        payment_method_type_id: "RETENCION_IVA",
        description: "Retención de IVA",
      },
      {
        payment_method_type_id: "RETENCION_IR",
        description: "Retención de renta",
      },
    ],

    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",

    dialogBuscarCliente: false,
    searchClientLoading: false,
    loadingTableClient: false,
    headersClient: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombre", align: "start", value: "first_name" },
      { text: "Apellido", align: "start", value: "last_name" },
      { text: "Idetnificación", align: "start", value: "id_value" },
    ],
    dataTableClient: [],
    clientSelected: [],
    cliente: "",
    newClient: "",
    ruleRequired: [(v) => !!v || "El campo es requerido"],
    clienteSeleccionado: false,
    btnGuardarPagoLoad: false
  }),
  watch: {
    popupDialog() {
      this.cargarDatos();
    },

    clientSelected: function(val) {
      this.clienteSeleccionado = val.length > 0;
      /*if (typeof this.clientSelected[0] != 'undefined') {
                this.openExistsQuoation = !!this.clientSelected[0].quotes.length
                this.existQuotes = this.clientSelected[0].quotes
            } else {
                this.openExistsQuoation = false
                this.existQuotes = []
            }*/
    },
  },
  computed: {
    ...mapState("master", [
      "loadingTable",
      "user",
      "tenantId",
      "paramAlertQuestion",
    ]),

    ...mapGetters("access", [
      "puedoSolicitarReembolsoCliente",
      "puedoSolicitarPagoServicioTerceros",
    ]),
    getTiposTarjetaFp() {
      let tt = [];
      for(let i=0; i< this.tiposTarjeta.length ; i++) {
        if(this.tiposTarjeta[i].payment_method_type_id == this.datosPago.payment_method_type_id) {
          tt.push(this.tiposTarjeta[i])
        }        
      }
      return tt;
    },
    estadoTransaccionBancoIcono() {
      return this.pago.gl_reconciliation_id == null
        ? "mdi-close-circle"
        : "mdi-check-circle";
    },
    estadoTransaccionBanco() {
      return this.pago.gl_reconciliation_id == null
        ? "No conciliada"
        : "Conciliada";
    },
    refExiste() {
      if (this.valorChequeEc == 0 || this.valorChequeEc == "")
        return "No se encontró la referencia";
      else return true;
    },

    validarRefCosto() {
      if (
        this.datosDevCheque.refCosto != "" &&
        (this.costoChequeEc == 0 || this.costoChequeEc == "")
      ) {
        return "No se encontró una transacción";
      } else {
        return true;
      }
    },
    esTransferencia() {
      if (
        this.pago.payment_method_type_id == "EFT_ACCOUNT" ||
        this.pago.payment_method_type_id == "FIN_ACCOUNT"
      ) {
        return true;
      }
      return false;
    },
    estaPagado() {
      if (this.pago.status_id == "PMNT_NOT_PAID") {
        return true;
      }

      return false;
    },
    estaAnulado() {
      if (this.pago.status_id == "PMNT_VOID") {
        return true;
      }
      return false;
    },
    esTarjeta() {
      if (
        this.pago.payment_method_type_id == "CREDIT_CARD" ||
        this.pago.payment_method_type_id == "DEBIT_CARD" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF_INT"
      ) {
        return true;
      }
      return false;
    },
    formasPagoPermiso() {
      return this.paymentMethodTypes.filter((item) => {
        if (item.payment_method_type_id == "BAJA_CARTERA_CC") {
          return this.puedoBajaCarteraCliente;
        } else {
          return true;
        }
      });
    },
    validarRefPago() {
      if (this.datosPago.payment_method_type_id != "CASH") {
        if (this.datosPago.payment_ref_num == "") {
          return "Ingrese la referencia";
        } else {
          return true;
        }
      }
    },
    validarMontoPago() {
      if (this.datosPago.monto == "" || this.datosPago.monto == 0) {
        return "Ingrese el monto del pago";
      } else {
        return true;
      }
    },
    validarEffectiveDate() {
      if (this.datosPago.effective_date == "") {
        return "Ingrese una fecha válida";
      } else {
        return true;
      }
    },
    validarCuentaBancaria() {
      if (this.datosPago.fin_account_id == "") {
        return "Seleccione una cuenta";
      } else {
        return true;
      }
    },
    validarTipoTarjeta() {
      if (this.datosPago.payment_method_id == "") {
        return "Seleccione una tarjeta";
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarPago() {
      this.setLoadingTable(true);
      this.setUrl("pago-recibido/" + this.$route.params.pagoId);
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.pago = res.data.detail.pago.payment;
          this.itemsPago = res.data.detail.pago.payapp;
          this.asientos = res.data.detail.pago.asientos;
          this.facturasPend =
            res.data.detail.facturas_pendientes != null
              ? res.data.detail.facturas_pendientes
              : [];
          this.proveedores = res.data.detail.proveedores;
          this.setLoadingTable(false);
          //console.log(this.pago)
          this.setTitleToolbar(
            "PAGO #" +
              this.pago.payment_id +
              " - " +
              this.pago.tipo_pago +
              " - " +
              this.pago.estado
          );
        })
        .then(() => {});
    },

    guardarPago() {
      if (!this.$refs.formPago.validate()) {
        return false;
      }

      this.btnGuardarPagoLoad = true
      this.setUrl("pago-recibido");
      this.requestApi({
        method: "POST",
        data: {
          payment_type_id: this.datosPago.payment_type_id,
          payment_method_type_id: this.datosPago.payment_method_type_id,
          payment_ref_num: this.datosPago.payment_ref_num,
          amount: this.datosPago.monto,
          comments: this.datosPago.comentario,
          effective_date: this.datosPago.effective_date,
          payment_method_id: this.datosPago.payment_method_id,
          invoice_id: null,
          fin_account_id: this.datosPago.fin_account_id,
          party_id_from: this.datosPago.party_id_from,
          tipoTran: "ingresarPago",
        },
      }).then((res) => {
        //this.dialogPago = false;
        //this.formInvoice(this.$route.params.invoiceId);
        console.log(res.data.detail);
        this.btnGuardarPagoLoad = false
        this.alertNotification({
          param: {
            html: res.data.detail.msg,
          },
        });
        if (this.popupDialog == false) {
          this.$router.push({
            path: "/ver-pago/" + res.data.detail.pago_id,
          });
        } else {
          this.datosPago= {
            payment_type_id: "CUSTOMER_PAYMENT",
            payment_ref_num: "",
            party_id_from: "",
            party_id_to: "",
            cliente_nombres: "",
            effective_date: new Date().toISOString().slice(0, 10),
            monto: 0,
            fin_account_id: "",
            payment_method_id: "",
            payment_method_type_id: "",
            pct_retencion: 0,
            monto_base: 0,
          }
          this.$emit("dialogAnticipoCerrado");
        }
      }).catch(()=>{
        this.btnGuardarPagoLoad = false
      });
    },

    cambioPmt() {
      this.datosPago.payment_method_id = "";
      this.datosPago.fin_account_id = "";
    },

    handlePageChange(value) {
      //this.cargarLista(false);
    },
    /*cargarCuentasBancarias() {
      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
      });
    },*/

    cargarDatos() {
     
      this.setLoadingTable(true);
      this.setUrl("pago-recibido");
      this.lista = [];
      this.requestApi({
        method: "POST",
        data: { tipoTran: "nuevoPago" },
      })
        .then((res) => {
          this.cuentasBancarias = res.data.detail.cuentas_bancarias;
          this.tiposTarjeta = res.data.detail.tipo_tarjetas;
          this.cliente = "";
          this.dataTableClient = [];
          this.clientSelected = [];
          this.newClient = "";
          (this.datosPago = {
            payment_type_id: "CUSTOMER_PAYMENT",
            payment_ref_num: "",
            party_id_from: "",
            party_id_to: "",
            cliente_nombres: "",
            effective_date: new Date().toISOString().slice(0, 10),
            monto: 0,
            fin_account_id: "",
            payment_method_id: "",
            payment_method_type_id: "",
            pct_retencion: 0,
            monto_base: 0,
          }),
            this.setLoadingTable(false);
        })
        .then(() => {});
    },
    submit() {
      return false;
    },
    buscarCliente() {
      if (!this.$refs.form_search_client.validate()) return false;

      this.searchClientLoading = true;
      this.loadingTableClient = true;
      this.setUrl("buscar-persona");
      this.requestApi({
        method: "POST",
        data: {
          cliente: this.cliente,
        },
      })
        .then((res) => {
          //console.log(res)
          if (res.data.detail.encontrado == "si") {
            //console.log(res.data.detail)
            this.dataTableClient = res.data.detail.clientes;
            //this.newClient = false
            //console.log(this.dataTableClient)
          }

          this.searchClientLoading = false;
          this.loadingTableClient = false;
        })
        .catch(() => {
          this.loadingTableClient = false;
          this.searchClientLoading = false;
        });
    },

    abrirDialogoBuscarCliente() {
      
      this.dialogBuscarCliente = true;
    },

    seleccionarCliente() {
      this.datosPago.party_id_from = this.clientSelected[0].party_id;
      this.datosPago.cliente_nombres =
        this.clientSelected[0].first_name +
        " " +
        this.clientSelected[0].last_name +
        " - " +
        this.clientSelected[0].party_id;
      //console.log(this.clientSelected[0]);
      this.dialogBuscarCliente = false;
    },

    closeDialogClient() {
      this.dialogBuscarCliente = false;
      this.clientSelected = [];
      this.dataTableClient = [];
      this.datosPago= {
        payment_type_id: "CUSTOMER_PAYMENT",
        payment_ref_num: "",
        party_id_from: "",
        party_id_to: "",
        cliente_nombres: "",
        effective_date: new Date().toISOString().slice(0, 10),
        monto: 0,
        fin_account_id: "",
        payment_method_id: "",
        payment_method_type_id: "",
        pct_retencion: 0,
        monto_base: 0,
      }
      this.location = "";
      this.cliente = "";
      this.newClient = false;
    },
  },

  mounted() {
    
    this.cargarDatos();

    //this.cargarCuentasBancarias();
    //this.geEstablishment()
    //this.getFormasPago()
    //this.cargarCuentasBancarias();
  },
};
</script>
